'use strict';

angular.module('cpformplastApp', [
  'cpformplastApp.auth',
  'cpformplastApp.accounts',
  'cpformplastApp.inventory',
  'cpformplastApp.orders',
  'cpformplastApp.machines',
  'cpformplastApp.items',
  'cpformplastApp.clients',
  'cpformplastApp.jobs',
  'cpformplastApp.deliveries',
  'cpformplastApp.constants',
  'ngCookies',
  'ngResource',
  'ngAnimate',
  'ngSanitize',
  'ui-notification',
  'btford.socket-io',
  'ui.router',
  'ui.bootstrap',
  'validation.match',
  'ngMaterial',
  'ngMaterialDatePicker',
  'materialCalendar',
  'dndLists',
  'nvd3'
], function($compileProvider) {
   $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|file|itms-services):/);
})
.config(function($urlRouterProvider, $locationProvider) {
  $urlRouterProvider.otherwise('/');
  $locationProvider.html5Mode(true);
});
